import { graphql, Link } from 'gatsby'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Footer from '../components/Footer'
import Header from '../components/Header'
import Helmet from '../components/Helmet'
  
interface IIndexPageProps {
  data: {
    site: {
      siteMetadata: {
        name: string;
        tagline: string;
      }
    },
    pages: any,
    books: any
  }
}
  
export const indexPageQuery = graphql`
  query AlleThemenPageQuery {
    pages: allMdx {
      totalCount
      edges {
        node {
          fileAbsolutePath
          frontmatter {
            title
          }
        }
      }
    }
    books: allBooksJson (
      sort: { order: ASC, fields: [order] },
    ) {
      edges {
        node {
          title
          chapters {
            pages {
              path
            }
            title
          }
        }
      }
    }
  }
`
  
export default (props: IIndexPageProps) => {
  const basePathStart = props.data.pages.edges[0].node.fileAbsolutePath.indexOf('/pages') + 6;

  const allPages: any = {};
  props.data.pages.edges.map(( { node }:any ) => {
    const path = node.fileAbsolutePath.substr(basePathStart).replace(/\.md$|\.mdx$/, '/');
    allPages[path] = node.frontmatter;
  });

  const books = props.data.books.edges.map(( { node }:any ) => {
    const chapters = node.chapters.map((chapter:any) => {
      const pages = chapter.pages.map((e:any) => {
        const pageData = allPages[e.path];
        if (pageData === undefined) {
          return (<li>{e.path}</li>);
        }
        return (<li key={e.path}>
          <Link to={e.path}>{allPages[e.path].title}</Link>
        </li>);
      });

      return (<li key={chapter.title}>{chapter.title}<ul>{pages}</ul></li>);
    });

    return (<li key={node.title}>{node.title}<ul>{chapters}</ul></li>);
  });

  return (<div>
    <Helmet title={'Sitemap'} />
    <Header />
    <Container>
      <Row>
        <Col>
          <h2>Nach Thema</h2>
          <ul>{books}</ul>
        </Col>
      </Row>
    </Container>
    <Footer />
  </div>)
};